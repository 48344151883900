window.SignInForm = (function($) {
  const SignInForm = function(ssoSigninUrl, emailDomains) {
    this.ssoSigninUrl = ssoSigninUrl;
    this.emailDomains = emailDomains;
    this.$email = $('#id_login');
    this.$signin_form = $('.c-login__signform');
    this.$email.focus();
    this.$email.on('keyup', (e) => {
      this.check_email(e);
    });
    this.$signin_form.on('submit', (e) => {
      this.check_email(e);
    });
  };

  SignInForm.prototype = {

    check_email(e) {
      let email = this.$email.val();
      if (email !== undefined) {
        const emailDomain = email.split('@')[1],
          // eslint-disable-next-line no-shadow
              emailDomainIndex = this.emailDomains.findIndex((e) => e.email_domain === emailDomain);
        if (emailDomainIndex !== -1) {
          if (e.type === 'submit') {
            e.preventDefault();
          }
          window.location = `${this.ssoSigninUrl}?idp=${this.emailDomains[emailDomainIndex].idp_metadata_url}`;
        }
      }
    },

  };

  return SignInForm;
}(window.$));
